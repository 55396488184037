import React from 'react';
import '../../fonts/fonts.css';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { motion } from 'framer-motion';
import Card from './card';
import { Flex, Box, Heading, Text } from '@chakra-ui/core';

// const NonStretchedImage = props => {
//   let normalizedProps = props;
//   if (props.fluid && props.fluid.presentationWidth) {
//     normalizedProps = {
//       ...props,
//       style: {
//         ...(props.style || {}),
//         maxWidth: props.fluid.presentationWidth
//       }
//     };
//   }

//   return <Image {...normalizedProps} />;
// };

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      fluid: file(relativePath: { eq: "HeroGOOD.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 600
            maxHeight: 800
            # maxWidth: 936
            # maxHeight: 1250
            cropFocus: CENTER
            fit: INSIDE
          ) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            presentationWidth
          }
        }
      }
    }
  `);

  return (
    <>
      {/* HERO BANDEAU JAUNE */}
      <Flex
        mt={2}
        mx={[2, 4, 16, 16]}
        h={[64, '18rem', '60vh', '60vh']}
        backgroundColor="brandPrimary"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        {/* TITRE ET ACCROCHE */}
        <Flex
          w="60%"
          h="100%"
          flexDirection="column"
          justifyContent="center"
          px={[4, 8, 8, 8]}
        >
          {' '}
          <motion.div
            initial={{
              opacity: 0,
              x: 50
            }}
            animate={{
              opacity: 1,
              x: 0
            }}
            transition={{
              duration: 2
            }}
          >
            <Heading
              fontFamily="graphik-bold"
              color="white"
              fontSize={['18px', '32px', '32px', '48px']}
              pb={4}
            >
              Voulez-vous donner plus de sens à votre vie professionnelle ?
            </Heading>
            <Text
              fontFamily="graphik-medium"
              color= '#292929'
              fontSize={['12px', '18px', '18px', '18px']}
            >
              Construisons ensemble votre identité professionnelle,
            </Text>
            <Text
              fontFamily="graphik-medium"
              color= '#292929'
              fontSize={['12px', '18px', '18px', '18px']}
            >
              forte et alignée avec vos talents
            </Text>
          </motion.div>
        </Flex>

        {/* PHOTO ET IMAGE  */}
        <Flex w="40%" h="100%" justifyContent="flex-end" alignItems="flex-end">
          {/* ICI CHANGER TAILLE IMAGE, larger BOX */}
          <Box w="400px" h={['75%', '75%', '75%', '100%']}>
            <Image
              fluid={data.fluid.childImageSharp.fluid}
              alt="Franck Gilardo"
              imgStyle={{
                objectFit: 'contain',
                objectPosition: 'right bottom'
              }}
              style={{ height: '100%', zIndex: '2' }}
            />
          </Box>
        </Flex>
      </Flex>

      {/* TROIS HEADLINES */}

      <Flex
        justifyContent="space-between"
        mx={[2, 4, 16, 16]}
        mt={[12, 12, 6, 6]}
        direction={['column', 'row', 'row', 'row']}
        alignItems="center"
      >
        <Heading
          fontFamily="openSansSemiBold"
          fontSize={['14px', '16px', '16px', '16px']}
          w={['100%', '33%', '33%', '33%']}
          textAlign="center"
          px={[4, 0, 16, 16]}
          pb={[4, 8, 0, 0]}
          color="brandGreyDark"
        >
          <Box>UNE OFFRE ORIGINALE</Box>
          <Box>D’ACCOMPAGNEMENT</Box>
        </Heading>
        <Heading
          fontFamily="openSansSemiBold"
          fontSize={['14px', '16px', '16px', '16px']}
          w={['100%', '33%', '33%', '33%']}
          textAlign="center"
          px={[4, 0, 16, 16]}
          pb={[4, 8, 0, 0]}
          color="brandGreyDark"
        >
          <Box>UNE MÉTHODE PRAGMATIQUE</Box>
          <Box>EN 3 MOIS</Box>
        </Heading>
        <Heading
          fontFamily="openSansSemiBold"
          fontSize={['14px', '16px', '16px', '16px']}
          w={['100%', '33%', '33%', '33%']}
          textAlign="center"
          px={[4, 0, 16, 16]}
          pb={[4, 8, 0, 0]}
          color="brandGreyDark"
        >
          <Box>TESTÉ ET APPROUVÉ</Box>
          <Box>PAR 150 DIRIGEANTS</Box>
        </Heading>
      </Flex>

      <Flex justifyContent="center" mt={8} id="apropos">
        <Arrow width="94" height="30" />
      </Flex>
    </>
  );
};

export default Hero;

const Arrow = props => {
  return (
    <Box>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 94 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.1748 4.79883L47.2983 35.3708L89.4219 4.79883"
          stroke="#F8BD44"
          stroke-width="8.70784"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Box>
  );
};

// // BACKUP

// import React from 'react';
// import '../../fonts/fonts.css';
// import { useStaticQuery, graphql } from 'gatsby';
// import Image from 'gatsby-image';
// import { motion } from 'framer-motion';
// import Card from './card';
// import { Flex, Box, Heading, Text } from '@chakra-ui/core';

// const NonStretchedImage = props => {
//   let normalizedProps = props;
//   if (props.fluid && props.fluid.presentationWidth) {
//     normalizedProps = {
//       ...props,
//       style: {
//         ...(props.style || {}),
//         maxWidth: props.fluid.presentationWidth
//       }
//     };
//   }

//   return <Image {...normalizedProps} />;
// };

// const Hero = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       fluid: file(relativePath: { eq: "HeroGOOD.png" }) {
//         childImageSharp {
//           fluid(
//             maxWidth: 600
//             maxHeight: 800
//             # maxWidth: 936
//             # maxHeight: 1250
//             cropFocus: CENTER
//             fit: INSIDE
//           ) {
//             ...GatsbyImageSharpFluid_tracedSVG
//             presentationWidth
//           }
//         }
//       }
//     }
//   `);

//   return (
//     <>
//       {/* HERO BANDEAU JAUNE */}
//       <Flex
//         mt={2}
//         mx={[2, 4, 16, 16]}
//         h={[64, 64, '60vh', '60vh']}
//         backgroundColor="brandPrimary"
//         justifyContent="space-between"
//         alignItems="flex-end"
//       >
//         {/* TITRE ET ACCROCHE */}
//         <Flex
//           w="60%"
//           h="100%"
//           flexDirection="column"
//           justifyContent="center"
//           px={[4, 8, 8, 8]}
//         >
//           {' '}
//           <motion.div
//             initial={{
//               opacity: 0,
//               x: 50
//             }}
//             animate={{
//               opacity: 1,
//               x: 0
//             }}
//             transition={{
//               duration: 2
//             }}
//           >
//             <Heading
//               fontFamily="graphik-bold"
//               color="white"
//               fontSize={['18px', '32px', '32px', '48px']}
//               pb={4}
//             >
//               Voulez-vous donner plus de sens à votre vie professionnelle ?
//             </Heading>
//             <Text
//               fontFamily="graphik-medium"
//               fontSize={['12px', '18px', '18px', '18px']}
//             >
//               Construisons ensemble votre identité professionnelle,
//             </Text>
//             <Text
//               fontFamily="graphik-medium"
//               fontSize={['12px', '18px', '18px', '18px']}
//             >
//               forte et alignée avec vos talents
//             </Text>
//           </motion.div>
//         </Flex>

//         {/* PHOTO ET IMAGE  */}
//         <Flex w="40%" h="100%" justifyContent="flex-end" alignItems="flex-end">
//           {/* ICI CHANGER TAILLE IMAGE, larger BOX */}
//           <Box w="400px" h={['75%', '75%', '75%', '100%']}>
//             <Image
//               fluid={data.fluid.childImageSharp.fluid}
//               alt="Franck Gilardo"
//               imgStyle={{
//                 objectFit: 'contain',
//                 objectPosition: 'right bottom'
//               }}
//               style={{ height: '100%', zIndex: '2' }}
//             />
//           </Box>
//         </Flex>
//       </Flex>

//       {/* TROIS HEADLINES */}

//       <Flex
//         justifyContent="space-between"
//         mx={[2, 4, 16, 16]}
//         mt={[12, 12, 6, 6]}
//         direction={['column', 'row', 'row', 'row']}
//       >
//         <Heading
//           fontFamily="openSansSemiBold"
//           fontSize={['12px', '16px', '16px', '16px']}
//           flexGrow={['0', '1', '1', '1']}
//           flexBasis={['auto', '0', '0', '0']}
//           textAlign="center"
//           px={[4, 12, 16, 16]}
//           pb={[4, 8, 0, 0]}
//           color="brandGreyDark"
//         >
//           <Box>UNE OFFRE ORIGINALE</Box>
//           <Box>D’ACCOMPAGNEMENT</Box>
//         </Heading>
//         <Heading
//           fontFamily="openSansSemiBold"
//           fontSize={['12px', '16px', '16px', '16px']}
//           flexGrow={['0', '1', '1', '1']}
//           flexBasis={['auto', '0', '0', '0']}
//           textAlign="center"
//           px={[20, 16, 16, 16]}
//           pb={[4, 8, 0, 0]}
//           color="brandGreyDark"
//         >
//           <Box>UNE METHODE PRAGMATIQUE</Box>
//           <Box>EN 3 MOIS</Box>
//         </Heading>
//         <Heading
//           fontFamily="openSansSemiBold"
//           fontSize={['12px', '16px', '16px', '16px']}
//           flexGrow={['0', '1', '1', '1']}
//           flexBasis={['auto', '0', '0', '0']}
//           textAlign="center"
//           px={16}
//           pb={[4, 8, 0, 0]}
//           color="brandGreyDark"
//         >
//           <Box>TESTÉ ET APPROUVÉ</Box>
//           <Box>PAR 150 DIRIGEANTS</Box>
//         </Heading>
//       </Flex>

//       <Flex justifyContent="center" mt={5} id="apropos">
//         <Arrow width="94" height="30" />
//       </Flex>
//     </>
//   );
// };

// export default Hero;

// const Arrow = props => {
//   return (
//     <Box>
//       <svg
//         width={props.width}
//         height={props.height}
//         viewBox="0 0 94 40"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           d="M5.1748 4.79883L47.2983 35.3708L89.4219 4.79883"
//           stroke="#F8BD44"
//           stroke-width="8.70784"
//           stroke-linecap="round"
//           stroke-linejoin="round"
//         />
//       </svg>
//     </Box>
//   );
// };
