import React from 'react';
import {
  Flex,
  Box,
  Link,
  Heading,
  Text,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure
} from '@chakra-ui/core';

const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Text
      bg="brandDark"
      textAlign="center"
      color="white"
      fontFamily="oxygenRegular"
      fontSize="14px"
      py={4}
    >
      <Text>
        <Link onClick={onOpen}>Mentions légales - Copyright © Bee 2020</Link>
      </Text>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} h="300px">
        <DrawerOverlay />
        <DrawerContent h="300px">
          <DrawerHeader borderBottomWidth="1px">
            <Flex justifyContent="space-between" alignItems="center">
              <Box fontFamily="oxygenBold">MENTIONS LEGALES</Box>
              <Box>
                <Button onClick={onClose} bg="none">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L1 13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 1L13 13"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Box>
            </Flex>
          </DrawerHeader>
          <DrawerBody fontSize={['12px', '12px', '14px', '14px']} py={4}>
            <Heading fontSize={['14px', '16px', '18px', '18px']} mt={4} mb={1}>
              DROITS D’AUTEUR
            </Heading>
            <Text>
              Le contenu de ce site est la propriété exclusive de Bee
              Consulting. Conformément aux articles L. 335-2 et suivants le Code
              de la propriété intellectuelle, toute reproduction est strictement
              interdite.
            </Text>
            <Heading fontSize={['14px', '16px', '18px', '18px']} mt={4} mb={1}>
              {' '}
              CREDITS
            </Heading>
            <Text>
              Design et développement du site :{' '}
              <Text as="span" color="brandPrimary" fontFamily="kohinoor-demi">
                Jean Gilardo
              </Text>
            </Text>

            <Text>
              Illustrations :{' '}
              <Link
                href="https://dribbble.com/Ivan_Haidutski"
                target="_blank"
                color="brandPrimary"
                fontFamily="kohinoor-demi"
              >
                Ivan Haidutski
              </Link>
            </Text>

            <Text>
              Design du logo :{' '}
              <Text as="span">
                <Link
                  href="http://www.marikamichelon.fr/"
                  target="_blank"
                  color="brandPrimary"
                  fontFamily="kohinoor-demi"
                >
                  Marika Michelon
                </Link>
              </Text>
            </Text>

            <Heading fontSize={['14px', '16px', '18px', '18px']} mt={4} mb={1}>
              LOI INFORMATIQUE ET LIBERTÉS
            </Heading>
            <Text fontSize={['8px', '8px', '14px', '14px']}>
              Conformément à la loi « informatique et libertés » du 6 janvier
              1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de
              rectification aux informations qui vous concernent, que vous
              pouvez exercer en vous adressant à Bee Consulting via le
              formulaire de contact du site internet. Les informations
              collectées sur ce site permettent à Bee Consulting de vous
              contacter dans le cadre d’un échange commercial. Ces données
              restent confidentielles et ne pourront être diffusées ou
              divulguées à de tierces personnes ou de tierces sociétés, sauf en
              cas de demande du client. Vous pouvez également, pour des motifs
              légitimes, vous opposer au traitement des données vous concernant.
            </Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Text>
  );
};

export default Footer;
