import React from 'react';

import SEO from '../components/seo';

import { ThemeProvider, CSSReset, theme } from '@chakra-ui/core';
import '../../src/fonts/fonts.css';
import newTheme from '../gatsby-plugin-chakra-ui/theme';

import MainPage from '../components/mainPage';
const IndexPage = () => (
  <>
    <SEO title="Bee : Révélez le sens de votre évolution professionnelle" />
    <ThemeProvider theme={newTheme}>
      <CSSReset />

      <MainPage />
    </ThemeProvider>
  </>
);

export default IndexPage;
