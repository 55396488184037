import React, { useMemo, useContext } from 'react';

import { IntersectionContext } from '../intersection-observer/IntersectionObserver';
import { motion } from 'framer-motion';

export const ScrollAppearRight = ({
  children,
  delayOrder, // order of appearance
  duration = 0.4,
  easing = [0.42, 0, 0.58, 1],
  // easing = [0.42, 0, 0.58, 1] // [number, number, number, number] | "linear" | "easeIn" | "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" | "backInOut" | "anticipate" | EasingFunction;
  orientation
}) => {
  const { inView } = useContext(IntersectionContext);
  const transition = useMemo(
    () => ({
      duration,
      delay: delayOrder / 5,
      ease: easing
    }),
    [duration, delayOrder, easing]
  );

  const variants = {
    hidden: {
      opacity: 0,
      transition,
      x: 500
    },
    show: {
      opacity: 1,
      transition: {
        duration: 1
      },
      x: 0
    }
  };

  return (
    <motion.div
      initial="hidden"
      animate={inView ? 'show' : 'hidden'}
      exit="hidden"
      variants={variants}
    >
      {children}
    </motion.div>
  );
};
