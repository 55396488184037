import React from 'react'
import '../../fonts/fonts.css'


import {
  Flex
} from '@chakra-ui/core';

const ArrowLeft = () => {
 
  return (
    <Flex 
          size = "50%" 
          py = {6}
          justifyContent = "center"
          > 
          
          <svg width="100" height="129" viewBox="0 0 133 129" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M85.5596 39.8287L60.3696 64.5L85.5596 89.1713L77.8046 96.75L44.8046 64.5L77.8046 32.25L85.5596 39.8287Z" fill="#1F1F1F"/>
</g>
<defs>
<clipPath id="clip0">
<rect x="132.804" width="129" height="132" transform="rotate(90 132.804 0)" fill="white"/>
</clipPath>
</defs>
</svg>

        </Flex>
  )
}

export default ArrowLeft