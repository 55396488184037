// TETE DE CHAPITRE SOULIGNEES EN ORANGE MT 18 MB 12 DONE
// Pas de dégradé dans la carte OPTIONNEL DONE
// APPORTS METHODE : ALIGNER CHIFFRES AVEC PHRASES
// CHECK CIBLES VERIFIER TAILLE TELEPHONE

/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import '../../fonts/fonts.css';
import Puzzle from './illustrations/Puzzle.svg';
import Assemble from './illustrations/Assemble.svg';
import Inspiration from './illustrations/Inspiration.svg';
import Rocket from './illustrations/Rocket.svg';
import Welcome from './illustrations/Welcome.svg';
import Checked from './illustrations/Checked.svg';
import VectorTop from './illustrations/VectorTop.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Flex,
  Box,
  PseudoBox,
  Image,
  Link,
  Heading,
  Text,
  Tooltip
} from '@chakra-ui/core';

import IntersectionObserver from '../intersection-observer/IntersectionObserver';
import { ScrollAppearRight } from '../animated-boxes/scrollAppearRight';
import { ScrollAppearLeft } from '../animated-boxes/scrollAppearLeft';
import BodyCard from './bodyCard';
import MethodBlock from './methodBlock';
import FormBee from './form';
import DemoCarouselGood from './CarouselGood';

const apports = [
  'Vous cernerez mieux ce que vous voulez absolument garder et changer dans votre histoire professionnelle',
  'Vous comprendrez vos motivations profondes grâce à la règle des "5 pourquoi"',
  'Vous allez apprendre comment vous positionner pour exprimer tous vos talents',
  'Vous découvrirez comment des marques ont réussi à créer  de la valeur et comment le faire vous même',
  'Vous apprendrez comment les autres vous perçoivent et à mettre en valeur votre différence'
];

const cibles = [
  'A ceux qui sont en transition professionnelle (outplacement) ou voulant 	faire le point pour mieux se projeter',
  'A ceux qui prennent un nouveau poste et veulent réussir les 100 premiers jours',
  'A ceux qui se posent avec leur DRH des questions sur leur évolution au 	sein de leur entreprise',
  'A ceux qui désirent fonder leur entreprise (réflexion sur le 	positionnement) '
];

const aboutMe = [
  "Après une vie professionnelle dans la publicité et le marketing pour Apple, Pepsi, Total, Citroën, Hermès ou Monoprix, j'ai lancé ma propre société de conseil en stratégie de marque en 2015, après un outplacement. Depuis 5 ans j'ai transposé mon expertise sur la marque, en concevant pour un leader du conseil RH un atelier de positionnement de dirigeant.",
  "Fort de ces expériences et du plaisir que j'ai eu à conseiller 150 dirigeants sur leur trajectoire professionnelle, j'ai construit une offre de conseil en évolution professionnelle concrète, personnalisée et réalisable en 3 mois.  ",
  "Révéler le sens de votre évolution professionnelle, c'est ce que je vous propose de faire ensemble, en allant au delà de ce que vous pensez être. Be all you can be !",
  'Franck Gilardo'
];

const handleLinkClickToTop = (e, target) => {
  if (typeof window !== 'undefined') {
    if (window.location.pathname === '/') {
      if (e) e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
const Etape = props => {
  return (
    <Heading
      mx="auto"
      mt={props.marginTop ? props.marginTop : 16}
      mb={props.marginBottom ? props.marginBottom : 12}
      pb={1}
      fontSize={['24px', '32px', '32px', '32px']}
      fontFamily="oxygenBold"
      boxShadow="0px 4px 0px #F8BD44"
      // boxShadow="0px 4px 0px #1A1A1A"
      textAlign="center"
    >
      <div id={props.id}></div>
      {props.title}
      {props.children}
    </Heading>
  );
};

const Apport = props => {
  return (
    <>
      <Flex mb={8}>
        <Box
          w="15%"
          fontFamily="oxygenBold"
          fontSize={['24px', '40px', '40px', '40px']}
          color="brandPrimary"
          textAlign="center"
        >
          {props.number}
        </Box>
        <Flex w="85%" alignItems="center">
          {props.apportText}
        </Flex>
      </Flex>
    </>
  );
};

const Cible = props => {
  return (
    <>
      <Flex mb={8}>
        <Box
          w="15%"
          fontFamily="oxygenBold"
          fontSize="40px"
          color="brandPrimary"
          textAlign="center"
        >
          <Flex alignContent="center" justifyContent="center">
            <Image
              w={['80%', '40%', '40%', '40%']}
              src={Checked}
              pt={'7px'}
              pr={4}
              alt="checked"
            />
          </Flex>
        </Box>
        <Box w="85%">{props.apportText}</Box>
      </Flex>
    </>
  );
};

const Body = () => {
  return (
    <>
      <Flex justifyContent="center" mb={0} mx={[8, 12, 16, 16]}>
        <Flex direction="column">
          {/* INSIDE BODY  */}

          {/* CE QUE CETTE METHODE VA VOUS APPORTER  */}

          <Etape
            title="Ce que ma méthode va vous apporter"
            id="monapproche"
            marginTop={[10, 20, 20, 20]}
          />
          <Flex direction={['column', 'row', 'row', 'row']}>
            <Flex
              w={['100%', '62.5%', '62.5%', '62.5%']}
              fontFamily="kohinoorDemi"
              direction="column"
              // fontSize= '18px'
            >
              {apports.map((apport, index) => (
                <Apport number={index + 1} apportText={apport} />
              ))}
            </Flex>
            <Flex
              w={['100%', '37.5%', '37.5%', '37.5%']}
              justifyContent="center"
              alignItems="center"
              p={['8', '2', '8', '8']}
                  display= {['none', 'block']}
            >
              <Image
                flex="1/2 1/2 auto"
                mb={0}
                src={Assemble}
                alt={'Bee Solution'}
              />
            </Flex>
          </Flex>

          {/* A QUI CELA S'ADRESSE ?  */}

          <Etape title="A qui cela s'adresse ?" />

          <Flex direction={['column', 'row', 'row', 'row']}>
            <Flex
              w={['100%', '37.5%', '37.5%', '37.5%']}
              justifyContent="center"
              alignItems="center"
              px={['16', '2', '8', '8']}
              mb={[8, 0, 0, 0]}
              display= {['none', 'block']}
            >
              
              <Image
                flex="1/2 1/2 auto"
                mb={0}
                src={Welcome}
                alt={'Bee clients'}
              />
            </Flex>
            <Flex
              w={['100%', '62.5%', '62.5%', '62.5%']}
              fontFamily="kohinoorDemi"
              direction="column"
            >
              {cibles.map((apport, index) => (
                <Cible number={index + 1} apportText={apport} />
              ))}
              <Text textAlign="center" fontFamily="oxygenBold" mt={2}>
                Tout en leur proposant une méthode plus créative et disruptive,
                plus compacte dans le temps (3 mois) et moins onéreuse
              </Text>
            </Flex>
          </Flex>

          {/* COMMENT CA MARCHE  */}
          <Etape title="Comment ça marche ?" id="methodologie" />

          {/* TEMPS 1 */}
          <IntersectionObserver>
            <ScrollAppearLeft>
              <Flex mb={[8, 8, 8, 12]} alignItems="center">
                <Flex
                  size={['0%', '50%', '45%', '45%']}
                  justifyContent="center"
                >
                  <Image h="120px" mb={0} src={Puzzle} alt={'Compréhension'} />
                </Flex>
                <Box size={['0%', '0%', '10%', '10%']}> </Box>
                <Box size={['100%', '50%', '45%', '45%']}>
                  <MethodBlock
                    title="Temps 1 : Compréhension"
                    checkList={[
                      'Analyse EPV (Emis / Perçu / Voulu) des réalisations et compétences',
                      "1er point sur le positionnement actuel et l'évolution souhaitée"
                    ]}
                  />
                </Box>
              </Flex>
            </ScrollAppearLeft>
          </IntersectionObserver>

          {/* TEMPS 2 */}
          {/* TODO RENDER PROPS BETTER PERFORMANCE ?  */}
          <IntersectionObserver>
            <ScrollAppearRight>
              <Flex mb={[8, 8, 8, 12]} alignItems="center">
                <Box size={['100%', '50%', '45%', '45%']}>
                  <MethodBlock
                    title="Temps 2 : Inspiration"
                    checkList={[
                      'Approche créative "out of the box" pour définir comme une marque son identité et positionnement',
                      "Création de scenarii professionnels et de leur viabilité grâce à l'approche 'Blue Ocean'"
                    ]}
                  />
                </Box>
                <Box size={['0%', '0%', '10%', '10%']}> </Box>
                <Flex
                  size={['0%', '50%', '45%', '45%']}
                  justifyContent="center"
                >
                  <Image
                    h="200px"
                    mb={0}
                    src={Inspiration}
                    alt={'Inspiration'}
                  />
                </Flex>
              </Flex>
            </ScrollAppearRight>
          </IntersectionObserver>

          {/* TEMPS 3 */}
          <IntersectionObserver>
            <ScrollAppearLeft>
              <Flex mb={[8, 8, 8, 12]} alignItems="center">
                <Flex
                  size={['0%', '50%', '45%', '45%']}
                  justifyContent="center"
                >
                  <Image
                    h="150px"
                    mb={0}
                    src={Rocket}
                    alt={'Validation Projet'}
                  />
                </Flex>
                <Box size={['0%', '0%', '10%', '10%']}> </Box>
                <Box
                  size={['100%', '50%', '45%', '45%']}
                  justifyContent="center"
                >
                  <MethodBlock
                    title="Temps 3 : Validation du projet"
                    checkList={[
                      'Plateforme de marque professionnelle',
                      'Plan d’action : cibles et actions à mettre en place ',
                      'Préparation des entretiens'
                    ]}
                  />
                </Box>
              </Flex>
            </ScrollAppearLeft>
          </IntersectionObserver>

          {/* OPTION  --> TO DO : RENDER PROP PATTERN FOR BETTER REUSABILITY */}
          <IntersectionObserver>
            <ScrollAppearRight>
              <Flex mb={[4, 4, 4, 8]}>
                <Box size={['100%', '50%', '45%', '45%']}>
                  <Box
                    p={5}
                    shadow="md"
                    borderBottom="solid"
                    borderBottomColor="brandPrimary"
                    // fontSize= '18px'
                  >
                    <Heading fontSize="xl" color="brandPrimary">
                      Optionnel
                    </Heading>
                    <Text mt={4}>
                      <Flex alignItems="flex-start">
                        <Box pt={'7px'} pr={4}>
                          {' '}
                          <svg
                            width="19"
                            height="14"
                            viewBox="0 0 19 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="checked"
                          >
                            <path
                              d="M17.3975 1.87891L6.39746 12.8789L1.39746 7.87891"
                              stroke="#F8BD44"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Box>
                        Linkedin, CV, Suivi d'entretiens
                      </Flex>
                      <Flex alignItems="flex-start">
                        <Box pt={'7px'} pr={4}>
                          {' '}
                          <svg
                            width="19"
                            height="14"
                            viewBox="0 0 19 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="checked"
                          >
                            <path
                              d="M17.3975 1.87891L6.39746 12.8789L1.39746 7.87891"
                              stroke="#F8BD44"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Box>
                        Programme d'harmonisation énergétique pour mettre en
                        oeuvre dans la durée sa transformation professionnelle
                        (sommeil, résistance au stress, volonté, résilience)
                      </Flex>
                    </Text>
                  </Box>
                </Box>

                <Box size={['0%', '10%', '10%', '10%']}> </Box>
                <Box size={['0%', '40%', '45%', '45%']}></Box>
              </Flex>
            </ScrollAppearRight>
          </IntersectionObserver>

          {/* BEE CARDS  */}
          <Heading
            mx="auto"
            mt={16}
            fontSize={['24px', '32px', '32px', '32px']}
            fontFamily="oxygenBold"
            textAlign="center"
          >
            {
              'Ce que font les marques les plus performantes pour se réinventer,'
            }
          </Heading>
          <Heading
            mx="auto"
            mb={8}
            pb={2}
            fontSize={['24px', '32px', '32px', '32px']}
            fontFamily="oxygenBold"
            textAlign="center"
            boxShadow="0px 4px 0px #F8BD44"
          >
            {"il ne tient qu'à vous de le faire"}
          </Heading>
          <Flex
            justifyContent="space-between"
            mx={[3, 8, 0, 0]}
            mt={4}
            direction={['column', 'column', 'row', 'row']}
          >
            <BodyCard
              letter="B"
              word="rand"
              text="Les marques ayant trouvé leur positionnement prennent les bonnes décisions stratégiques"
              textHover="Il n'y a pas de vent favorable à celui qui ne sait pas où il va"
            />
            <BodyCard
              letter="E"
              word="mpowerment"
              text="La valeur d'une marque, c'est ce qu'elle vous permet de faire"
              textHover="En 3 mois vous aurez tous les outils pour mettre en oeuvre votre transformation professionnelle"
            />
            <BodyCard
              letter="E"
              word="nergy"
              text="Focus et cohérence, clés du succès des marques qui durent."
              textHover="Canaliser son énergie et la mobiliser dans la durée, pour un résultat tangible"
            />
          </Flex>

          {/* PARCOURS ET REFERENCES  */}
          <Etape
            title="Parcours et références"
            id="references"
            // marginBottom={4}
            marginTop={24}
            marginBottom={[0, 8, 8, 0]}
            children = {<span> <Link
                  href="https://www.linkedin.com/in/franckgilardo/?originalSubdomain=fr"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    as="span"
                    icon={faLinkedin}
                    color="#F8BD44"
                  />
                </Link></span>}
          />
          <Flex
            textAlign="center"
            mx={50}
            mb={[8, 8, 16, 16]}
            flexDirection="column"
          ></Flex>
          
           <Flex direction={['column', 'column', 'column', 'column']} >
            <Flex
              direction={["column", "row"]}
              // pt={8}
              size={['100%', '100%', '100%', '100%']}
              fontSize={['14px', '14px', '16px', '16px']}
            //  justifyContent= 'space-between'
            >
              <Flex
              direction="column"
             
            >
              {/* <Box mb={3}>
                <Text as="span">Franck Gilardo </Text>
                <Link
                  href="https://www.linkedin.com/in/franckgilardo/?originalSubdomain=fr"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    as="span"
                    icon={faLinkedin}
                    color="#F8BD44"
                  />
                </Link>
              </Box> */}

              {/* <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box> */}
              <Text my={3}>
                Fondateur de
                <Box as="span" color="brandPrimary">
                  <span> </span>
                  {/* <Link href="http://www.beeconsulting.fr/" target="_blank"> */}
                    BEE Consulting
                  {/* </Link> */}
                </Box>
                , conseil en positionnement d'entreprises (Ayming, Club Med
                Gym...)
              </Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
              <Text my={3}>
                Ex VP Stratégie Havas, DG Y&R Corporate, DGA CLM BBDO
              </Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
              <Text my={3}>Business Angel (Gemmyo)</Text>
              {/* <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box> */}
              </Flex>
              <Box width= '10%'></Box>
               <Flex
              direction="column"
             
            >
              <Text my={3}>
                Professeur en stratégie de marques : Sciences Po Paris
                (2012-2018) / INSEAD-Cedep (2012-2016)
              </Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
                 <Text my={3}>
                Diplomé de l'ESCP Business School (Master) et de l'INSEAD (EMBA)
              </Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
               <Text my={3}>
                Etudiant en Energétique Chinoise, Naturopathie
                (4ème année)
              </Text>
           
              {/* <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box> */}
              </Flex>
            </Flex>
            <Flex
              size={['100%', '100%', '100%', '100%']}
              bg="brandPrimary"
              pt={8}
              direction="column"
              mt={[6, 6, 6, 6]}
            >
              <Heading fontSize="20px" textAlign="center">
                <Tooltip
                  fontFamily="oxygenBold"
                  label="Les noms ont été masqués pour préserver l’anonymat, recommandations disponibles sur demande"
                >
                  Références
                </Tooltip>
              </Heading>
              <DemoCarouselGood />
            </Flex>
          </Flex>
          {/* <Flex direction={['column', 'column', 'column', 'row']}>
            <Flex
              direction="column"
              pt={8}
              size={['100%', '100%', '100%', '50%']}
              fontSize={['14px', '14px', '16px', '16px']}
              pr={8}
            >
              <Box mb={3}>
                <Text as="span">Franck Gilardo </Text>
                <Link
                  href="https://www.linkedin.com/in/franckgilardo/?originalSubdomain=fr"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    as="span"
                    icon={faLinkedin}
                    color="#F8BD44"
                  />
                </Link>
              </Box>

              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
              <Text my={3}>
                Fondateur de
                <Box as="span" color="brandPrimary">
                  <span> </span>
                  <Link href="http://www.beeconsulting.fr/" target="_blank">
                    BEE Consulting
                  </Link>
                </Box>
                , conseil en positionnement d'entreprises (Ayming, Club Med
                Gym...)
              </Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
              <Text my={3}>
                VP Stratégie Havas, DG Y&R Corporate, DGA CLM BBDO
              </Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
              <Text my={3}>Business Angel (Gemmyo)</Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
              <Text my={3}>
                Professeur en stratégie de marques : Sciences Po Paris
                (2012-2018) / INSEAD-Cedep (2012-2016)
              </Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
              <Text my={3}>
                Etudiant en Energétique Chinoise, Homéopathie, Naturopathie
                (3ème année)
              </Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
              <Text my={3}>
                Diplomé de l'ESCP Business School (Master) et de l'INSEAD (EMBA)
              </Text>
              <Box
                borderBottom="solid 2px"
                borderBottomColor="black"
                w="20px"
              ></Box>
            </Flex>
            <Flex
              size={['100%', '100%', '100%', '50%']}
              bg="brandPrimary"
              pt={8}
              direction="column"
              mt={[4, 4, 0, 0]}
            >
              <Heading fontSize="20px" textAlign="center">
                <Tooltip
                  fontFamily="oxygenBold"
                  label="Les noms ont été masqués pour préserver l’anonymat, recommandations disponibles sur demande"
                >
                  Références
                </Tooltip>
              </Heading>
              <DemoCarouselGood />
            </Flex>
          </Flex> */}

          {/* A PROPOS */}
          {/* <Etape title="A propos" id="apropos" marginTop={[10, 20, 20, 20]} /> */}
          <Etape title="A propos" id="apropos" />
          {aboutMe.map(morceau => (
            <Box
              fontFamily="kohinoorDemi"
              mb={4}
              textAlign="justify"
              mx={[4, 8, 32, 32]}
            >
              {morceau}
            </Box>
          ))}
          {/* FORMULAIRE CONTACT  */}
          {/* <div id="contact"></div> */}
          <Etape title="Parlons nous" id="contact" />
          <Text
            color="brandGrey"
            fontFamily="kohinoorDemi"
            fontSize="16px"
            textAlign="center"
            mb={[8, 8, 16, 16]}
          >
            Pour un premier rendez vous sans engagement, vous pouvez me
            contacter par{' '}
            <Tooltip
              as="span"
              fontFamily="oxygenBold"
              label="franckgilardo@gmail.com"
              color="brandPrimary"
              bg="#ffffff"
            >
              <Box
                color="brandPrimary"
                as="span"
                borderBottom="1px solid #F8BD44"
              >
                <span> </span>
                mail
              </Box>
            </Tooltip>
            <span> </span>
            ou{' '}
            <Tooltip
              as="span"
              fontFamily="oxygenBold"
              label="06 17 13 73 69"
              color="brandPrimary"
              bg="#ffffff"
            >
              <Box
                color="brandPrimary"
                as="span"
                borderBottom="1px solid #F8BD44"
              >
                <span> </span>
                téléphone
              </Box>
            </Tooltip>
            , ou bien directement avec le formulaire suivant :
          </Text>
          <FormBee />
          <Link onClick={e => handleLinkClickToTop(e, '#top')} mb={8}>
            <Image w="70px" mb={0} src={VectorTop} m="0 auto" alt={'Top'} />
          </Link>

          {/* <Text
            color="brandGrey"
            fontFamily="oxygenBold"
            fontSize="12px"
            textAlign="center"
            my={4}
          >
            Revenir en haut du site
          </Text> */}
          {/* PUT BODY CODE INSIDE, BEFORE TWO FLEX TAG*/}
        </Flex>
      </Flex>
    </>
  );
};

export default Body;
