import React from 'react';
import { Box, Flex, Heading, Text, Image } from '@chakra-ui/core';
import Checked from './illustrations/Checked.svg';

const MethodBlock = ({ title, bullet1, bullet2, bullet3, checkList }) => {
  return (
    <Box
      p={5}
      shadow="md"
      borderBottom="solid"
      borderBottomColor="brandPrimary"
      // fontSize= '18px'
    >
      <Heading fontSize="xl">{title}</Heading>
      <Text mt={4}>
        {checkList.map(element => (
          <Flex alignItems="flex-start">
            <Image src={Checked} pt={'7px'} pr={4} alt="checked" />
            {element}
          </Flex>
        ))}
        <div> {bullet1} </div>
        <div> {bullet2} </div>
        <div> {bullet3} </div>
      </Text>
    </Box>
  );
};

export default MethodBlock;

// const MethodBlock = ({ title, bullet1, bullet2, bullet3 }) => {
//   return (
//     <Box
//       p={5}
//       shadow="md"
//       borderBottom="solid"
//       borderBottomColor="brandPrimary"
//     >
//       <Heading fontSize="xl">{title}</Heading>
//       <Text mt={4}>
//         <div> {bullet1} </div>
//         <div> {bullet2} </div>
//         <div> {bullet3} </div>
//       </Text>
//     </Box>
//   );
// };

// export default MethodBlock;
