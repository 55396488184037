import React from 'react';
// import {
//   ThemeProvider,
//   CSSReset,
//   theme
// } from '@chakra-ui/core';
import NavbarNew from './Navbar/navbar2';
// import Navbar from './Navbar/navbar';
import Hero from './Hero/hero';
import Body from './Body/body';
import Footer from './Footer/footer';
import '../../src/fonts/fonts.css';

const MainPage = () => (
  <>
    {/* <Navbar /> */}
    <NavbarNew />
    <Hero />
    <Body />
    <Footer />
  </>
);
export default MainPage;
