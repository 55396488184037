import React from 'react';
import {
  Flex,
  Box,
  Button,
  Heading,
  FormControl,
  Input,
  useToast,
  Textarea
} from '@chakra-ui/core';
import '../../fonts/fonts.css';

import { useFormik } from 'formik';
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_1btR4I1BKDXXeTSRIoJ66");

const validate = values => {
  const errors = {};

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

const FormBee = () => {
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: ''
    },
    validate,
    // onSubmit: (values) => {
    //   alert(JSON.stringify(values, null, 2));
    // }
    onSubmit: (values, { resetForm, setStatus }) => {
      let service_id = 'beemailing';
      let template_id = 'template_fLD2gA7W';
      let user_id = 'user_1btR4I1BKDXXeTSRIoJ66';
   
      emailjs.send(service_id, template_id, values, user_id).then(
        response => {
          // console.log('SUCCESS!', response.status, response.text, values);
          toast({
            title: 'Merci',
            description: 'Votre email a bien été envoyé',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
          resetForm({});
          setStatus({ success: true });
        },
        err => {
          alert("l'email n'a pas été envoyé suite à une erreur. Vous pouvez me contacter par mail (franckgilardo@gmail.com) ou par téléphone (06 17 13 73 69)");
        }
      );
    }
  });

  // const handleFormSubmit = () => {
  //   emailjs.send('gmail','template_fLD2gA7W', values, 'user_1btR4I1BKDXXeTSRIoJ66')
  //   .then((response) => {
  //      console.log('SUCCESS!', response.status, response.text);
  //   }, (err) => {
  //      console.log('FAILED...', err);
  //   });
  // }

  return (
    <>
      <Flex justifyContent="center" mb={8}>
        <Flex
          direction="column"
          justifyContent="center"
          textAlign="center"
          border="1px solid"
          borderColor="#DFDFDF"
          borderRadius="10px"
          boxSizing="border-box"
          p={8}
        >
          <Heading mb={8} fontSize={20} color="brandGrey">
            Formulaire de contact
          </Heading>

          <form onSubmit={formik.handleSubmit}>
            <Flex>
              <FormControl isRequired mr={2}>
                <Input
                  id="name"
                  placeholder="Nom"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </FormControl>

              <FormControl isRequired>
                <Input
                  id="email"
                  placeholder="E-mail"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.errors.email ? (
                  <Box color="#E20338">{formik.errors.email}</Box>
                ) : null}
              </FormControl>
            </Flex>
            <Textarea
              my={4}
              isRequired
              id="message"
              placeholder="Votre message"
              onChange={formik.handleChange}
              value={formik.values.message}
            />
            <Button
              backgroundColor="brandPrimary"
              color="white"
              type="submit"
              // onClick={() =>
              //   toast({
              //     title: 'Merci',
              //     description: 'Votre email a bien été envoyé',
              //     status: 'success',
              //     duration: 9000,
              //     isClosable: true
              //   })
              // }
            >
              {' '}
              Me contacter{' '}
            </Button>
          </form>
        </Flex>
      </Flex>
    </>
  );
};

export default FormBee;
