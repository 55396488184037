import React from 'react';
import Carousel from 'nuka-carousel';
import { Flex, Box, PseudoBox, Heading, Text, Button } from '@chakra-ui/core';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import '../../fonts/fonts.css';

const QuotationFirst = () => {
  return (
    <>
      <span>
        <svg
          width="30"
          height="20"
          viewBox="0 0 55 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.604984 23.627C0.599984 23.765 0.583984 23.9 0.583984 24.04C0.583984 24.087 0.589984 24.132 0.590984 24.178C0.589984 24.27 0.583984 24.361 0.583984 24.453L0.603985 24.439C0.815985 30.605 5.86898 35.542 12.086 35.542C18.438 35.542 23.588 30.393 23.588 24.04C23.588 18.244 19.296 13.463 13.719 12.668C15.105 8.07302 19.364 4.73002 24.414 4.73002V0.624023C11.521 0.625023 1.04098 10.841 0.604984 23.627Z"
            fill="black"
          />
          <path
            d="M30.4409 23.627C30.4359 23.765 30.4199 23.9 30.4199 24.04C30.4199 24.087 30.4269 24.132 30.4269 24.178C30.4259 24.27 30.4199 24.361 30.4199 24.453L30.4409 24.439C30.6529 30.605 35.7059 35.542 41.9219 35.542C48.2749 35.542 53.4239 30.393 53.4239 24.04C53.4239 18.244 49.1319 13.463 43.5549 12.668C44.9409 8.07302 49.1999 4.73002 54.2489 4.73002V0.624023C41.3559 0.625023 30.8779 10.841 30.4409 23.627Z"
            fill="black"
          />
        </svg>
      </span>
    </>
  );
};

const QuotationLast = () => {
  return (
    <>
      <span>
        <svg
          width="30"
          height="20"
          viewBox="0 0 54 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M53.895 12.373C53.9 12.235 53.916 12.1 53.916 11.96C53.916 11.913 53.91 11.868 53.909 11.822C53.91 11.73 53.916 11.639 53.916 11.547L53.896 11.561C53.684 5.39498 48.631 0.457977 42.414 0.457977C36.062 0.457977 30.912 5.60698 30.912 11.96C30.912 17.756 35.204 22.537 40.781 23.332C39.395 27.927 35.136 31.27 30.086 31.27V35.376C42.979 35.375 53.459 25.159 53.895 12.373Z"
            fill="black"
          />
          <path
            d="M24.0591 12.373C24.0641 12.235 24.0801 12.1 24.0801 11.96C24.0801 11.913 24.0731 11.868 24.0731 11.822C24.0741 11.73 24.0801 11.639 24.0801 11.547L24.0591 11.561C23.8471 5.39498 18.7941 0.457977 12.5781 0.457977C6.22508 0.457977 1.07607 5.60698 1.07607 11.96C1.07607 17.756 5.36808 22.537 10.9451 23.332C9.55908 27.927 5.30008 31.27 0.251076 31.27V35.376C13.1441 35.375 23.6221 25.159 24.0591 12.373Z"
            fill="black"
          />
        </svg>
      </span>
    </>
  );
};

class DemoCarouselGood extends React.Component {
  quotes = [
    {
      client: 'Jean, Directeur Stratégie Ventes et Marketing',
      quote:
        "J'ai eu le plaisir de rencontrer Franck dans une phase de transition pour laquelle sa démarche de positionnement professionnel a été un vrai tournant. Être en accord avec moi-même et mon projet professionnel m'a conduit à faire des démarches et des choix plus pertinents, à produire des entretiens plus efficaces et m'a permis de trouver un job aussi motivant qu'épanouissant. Un an plus tard j'ai fait appel à lui pour m'aider au repositionnement de l'activité d'une des divisions de mon entreprise pour lequel j'ai pu encore apprécier ses qualités professionnelles et humaines. Je le recommande pleinement pour ces deux types de projets."
    },
    {
      client: 'Jean Luc, CTO',
      quote:
        'Franck m’a permis de me remettre en cause, j’ai alors pris conscience que j’avais depuis longtemps envie de lancer ma propre entreprise et son approche m’a permis de la positionner, il m’a fait gagner un temps précieux.'
    },
    {
      client: 'Nadège, CMO',
      quote:
        'Etant moi même une spécialiste en marketing et positionnement stratégique, j’étais dans un premier temps curieuse de ce que j’allais pouvoir apprendre pensant connaître assez bien mon parcours et mes atouts. Je recommande l’approche utilisée par Franck car non seulement elle permet de se poser les bonnes questions mais permet surtout d’identifier ce qu’il y a de vraiment différent dans une histoire professionnelle.'
    },
    {
      client: 'Bertrand, CFO',
      quote:
        'Le marketing n’est à priori pas mon sujet, ayant fait toute ma carrière dans le contrôle financier. J’avoue avoir pensé dans un premier temps à utiliser directement mon réseau pour rebondir. L’approche de Franck est synthétique, factuelle et permet de résumer après seulement quelques jours de travail en une seule page son scenario professionnel pour identifier facilement forces et faiblesses pour les entretiens.'
    },
    {
      client: 'Loic, DG',
      quote:
        'Je vais être franc, au début je n’étais pas tenté et j’ai décidé de rencontrer Franck sur la recommandation d’un ami. Je m’attendais à un prêchi-prêcha fumeux teinté de marketing, et bien il n’en est rien. Cette méthode oblige à s’interroger de façon mesurable et vous oblige à faire des choix clairs pour sortir des positionnements standardisés qui radotent le cv. Un véritable révélateur en ce qui me concerne pour la reprise d’une ETI et surtout un gain de temps indéniable.'
    },
    {
      client: 'Alexis, DRH',
      quote:
        'Une belle rencontre, de quelqu’un qui a le recul nécessaire et la bienveillance pour écouter, conseiller sans imposer et mettre une bonne dose de bonne humeur et d’envie à la réflexion professionnelle. Un apport indéniable.'
    },
    {
      client: 'Elsa/ Coordination de la rénovation de Notre-Dame de Paris',
      quote:
        "La qualité d'accompagnement et la vision pertinente de Franck ont mis en évidence plusieurs axes de recherche. Sa méthode et sa personnalité bienveillante ont permis un échange constructif faisant ressortir ma singularité, mon potentiel et un regain de confiance pour avancer dans mon parcours. Une chance d'avoir croisé le chemin de Franck !"
    }
  ];

  render() {
    return (
      <Carousel
        style={{ outline: 'none' }}
        pauseOnHover="true"
        autoplay="true"
        autoplayReverse="true"
        autoplayInterval="10000"
        defaultControlsConfig={{
  pagingDotsStyle: {
    margin: '8px'
  }
}}
        renderCenterLeftControls={({ previousSlide }) => (
          <button onClick={previousSlide} style={{ outline: 'none' }}>
            <Flex justifyContent="flex-start" w="100%">
              {' '}
              <div>
                <ArrowLeft />{' '}
              </div>
            </Flex>
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <Flex
            onClick={nextSlide}
            style={{ outline: 'none', cursor: 'pointer' }}
            justifyContent="flex-end"
          >
            <ArrowRight />
          </Flex>
        )}
      >
        {this.quotes.map(el => (
          <Flex direction="column" px={16} py={16} alignContent="center" justifyContent='center'>
            <Text fontFamily="kohinoorBook" position="relative">
              <Box position="absolute" top="-50px" left="-50px">
                {' '}
                <QuotationFirst />{' '}
              </Box>
              {el.quote}
              <Box position="absolute" bottom="-10px" right="-50px">
                {' '}
                <QuotationLast />{' '}
              </Box>
            </Text>
            <Text textAlign="center" mt={8}>
              {' '}
              {el.client}
            </Text>
          </Flex>
        ))}
      </Carousel>
    );
  }
}

export default DemoCarouselGood;
// import React, {useState} from 'react'
// import {
//   Flex,
//   Box,
//   PseudoBox,
//   Heading,
//   Text,
// } from '@chakra-ui/core';
// import {css} from '@emotion/core'

// const TestimonyCarousel = () => {

//   const [current, setCurrent] = useState(quotes[0])
//   const [active, setActive] = useState(0)

//   const handleSetClick = (event) => {
//     setCurrent(quotes[event.target.getAttribute("data-quote")])
//   }
//   return (
//     <Flex
//     direction= "column"
//     alignItems = "center"
//     textAlign = "center"
//     >
//       <p>{current.quote}</p>
//       <p>{current.client}</p>
//       <Flex
//       h = "20px"
//       w = "20px"
//       alignItems= "center"
//       justifyContent = "center"
//       >
//         {Object.keys(quotes).map(index => (

//           <PseudoBox
//           h = "20px"
//           w = "20px"
//           px = "8px"
//           textAlign = "center"
//           cursor= "pointer"
//           onClick={event => handleSetClick(event)}
//           data-quote = {index}
//           key = {index}
//           // _before = {{
//           //   display : "inline-block",
//           //   content: `" "`,
//           //   height: "6px",
//           //   borderRadius: `50px`,
//           //   width: "6px",
//           //   backgroundColor : "#FFFFFF",
//           //   transition: "backgroundColor 0.3s ease",
//           // }}

//         ><PseudoBox
//             data-quote = {index}
//             display = "inline-block"
//             height= "6px"
//             borderRadius= "50px"
//             width="6px"
//             backgroundColor ="#EEEEEE"
//             transition="backgroundColor 0.3s ease"
//             _hover = {{
//               bg: active ? "#000000" : "#EEEEEE"
//             }}
//           ></PseudoBox> </PseudoBox>

//         ))}
//       </Flex>
//     </Flex>
//   )
// }

// export default TestimonyCarousel
