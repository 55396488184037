import React from 'react';
import '../../fonts/fonts.css';

import { Flex, Box, PseudoBox, Text } from '@chakra-ui/core';

export const BodyCard = props => {
  return (
    <Flex
      direction="column"
      textAlign="center"
      mt={8}
      flexGrow={['0', '0', '1', '1']}
      flexBasis={['auto', 'auto', '0', '0']}
      // flexGrow="1"
      // flexBasis="0"
      mx={8}
    >
      <Box>
        <Text
          as="span"
          color="brandPrimary"
          fontSize={32}
          fontFamily="oxygenBold"
        >
          {props.letter}
        </Text>
        <Text as="span" fontFamily="oxygenRegular" fontSize={24}>
          {props.word}
        </Text>
      </Box>
      <PseudoBox
        display="flex"
        height={40}
        mt={4}
        px={2}
        borderRadius="5px"
        boxShadow="0px 4px 8px #F8BD44"
        alignItems="center"
        role="group"
        _hover={{ bg: 'brandPrimary' }}
      >
        <PseudoBox _groupHover={{ display: 'none' }}>
          <Text>{props.text}</Text>
        </PseudoBox>

        <PseudoBox display="none" _groupHover={{ display: 'block' }}>
          <Text fontSize={['16px', '18px', '16px', '16px']}>
            {props.textHover}
          </Text>
        </PseudoBox>
      </PseudoBox>
    </Flex>
  );
};

export default BodyCard;
