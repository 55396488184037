import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import '../../fonts/fonts.css';

import {
  Flex,
  Box,
  Link,
  Button,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  PseudoBox
} from '@chakra-ui/core';
import scrollToElement from 'scroll-to-element';

const handleLinkClick = (e, target) => {
  if (typeof window !== 'undefined') {
    if (window.location.pathname === '/') {
      if (e) e.preventDefault();
      scrollToElement(target, {
        offset: -120, // Offset a fixed header if you please
        duration: 1000
      });
    }
  }
};

const MenuItems = ({ children }) => (
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
);

const Navbar = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const data = useStaticQuery(graphql`
    query {
      fixed: file(relativePath: { eq: "logo-fondblanc-final.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          big: fixed(width: 150, height: 75) {
            ...GatsbyImageSharpFixed_tracedSVG
          }

          medium: fixed(width: 120, height: 60) {
            ...GatsbyImageSharpFixed_tracedSVG
          }

          small: fixed(width: 80, height: 40) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <>
      <Flex
        as="nav"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        py={4}
        mx={[2, 4, 16, 16]}
        position="sticky"
        top="0"
        backgroundColor="white"
        zIndex="100"
      >
        <Flex
          justifyContent="flex-start"
          alignItems="center"

          // backgroundSize="cover"
        >
          <Box display={['none', 'none', 'block', 'block']}>
            <Image
              fixed={data.fixed.childImageSharp.big}
              alt="Bee logo"
              // margin="0 auto"
            />
          </Box>
          <Box display={['none', 'block', 'none', 'none']}>
            <Image
              fixed={data.fixed.childImageSharp.medium}
              alt="Bee logo"
              // margin="0 auto"
            />
          </Box>

          <Box display={['block', 'none', 'none', 'none']}>
            <Image
              fixed={data.fixed.childImageSharp.small}
              alt="Bee logo"
              // margin="0 auto"
            />
          </Box>
        </Flex>

        {/* DRAWER SMALL DEVICES */}
        <Flex
          justifyContent="center"
          alignItems="center"
          display={['block', 'none', 'none', 'none']}
        >
          <Button onClick={onOpen}>
            <svg fill="black" width="20px" viewBox="0 0 20 20">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </Button>
          <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent backgroundColor="brandPrimary">
              <DrawerHeader fontFamily="graphik-medium" mb={6}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Link> Bienvenue </Link>
                  <Button onClick={onClose} bg="brandPrimary">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 1L1 13"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 1L13 13"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                </Flex>
              </DrawerHeader>
              <DrawerBody
                fontFamily="open-sans-semi-bold"
                fontSize="xs"
                color="white"
              >
                <p>
                  <Link onClick={e => handleLinkClick(e, '#monapproche')}>
                    MON APPROCHE
                  </Link>
                </p>
                <br />
                <p>
                  <Link onClick={e => handleLinkClick(e, '#methodologie')}>
                    METHODOLOGIE
                  </Link>
                </p>
                <br />
                <p>
                  <Link onClick={e => handleLinkClick(e, '#references')}>
                    PARCOURS ET REFERENCES
                  </Link>
                </p>
                <br />

                <p>
                  <Link onClick={e => handleLinkClick(e, '#contact')}>
                    ME CONTACTER
                  </Link>
                </p>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>

        {/* 3 LINKS MED-BIG DEVICES */}
        <Flex
          display={['none', 'flex', 'flex', 'flex']}
          textAlign="center"
          flexGrow="0.5"
          justifyContent="space-around"
          fontSize={['10px', '10px', 'xs', 'xs']}
          color="brandGrey"
        >
          {/* <MenuItems>
            <Link
              onClick={e => handleLinkClick(e, '#apropos')}
              fontFamily="open-sans-semi-bold"
            >
              A PROPOS
            </Link>
          </MenuItems> */}
          <MenuItems>
            <Link
              onClick={e => handleLinkClick(e, '#monapproche')}
              fontFamily="open-sans-semi-bold"
            >
              MON APPROCHE
            </Link>
          </MenuItems>
          <MenuItems>
            <Link
              onClick={e => handleLinkClick(e, '#methodologie')}
              fontFamily="open-sans-semi-bold"
            >
              METHODOLOGIE
            </Link>
          </MenuItems>
          <MenuItems>
            <Link
              onClick={e => handleLinkClick(e, '#references')}
              fontFamily="open-sans-semi-bold"
            >
              PARCOURS ET REFERENCES
            </Link>
          </MenuItems>
        </Flex>

        {/* BUTTON  */}
        <Flex
          display={['none', 'block', 'block', 'block']}
          justifyContent="center"
        >
          {/* <Button
            fontSize="xs"
            fontFamily="open-sans-semi-bold"
            variant="outline"
            color="#414042"
            bg="transparent"
            shadow="sm"
            _hover={{ color: 'white', bg: 'brandPrimary' }}
            onClick={e => handleLinkClick(e, '#contact')}
          >
            Parlons nous
          </Button> */}
          <Button
            fontSize={['12px', '12px', '12px', '12px']}
            fontFamily="open-sans-semi-bold"
            variant="outline"
            color="#ffffff"
            bg="brandPrimary"
            shadow="sm"
            _hover={{ color: 'white', bg: 'brandPrimary' }}
            onClick={e => handleLinkClick(e, '#contact')}
          >
            Parlons nous
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default Navbar;

{
  /* DRAWER SMALL DEVICES OLD */
}
{
  /* <Flex
  justifyContent="center"
  alignItems="center"
  display={['block', 'none', 'none', 'none']}
>
  <Button onClick={onOpen}>
    <svg fill="black" width="20px" viewBox="0 0 20 20">
      <title>Menu</title>
      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
  </Button>
  <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
    <DrawerOverlay />
    <DrawerContent backgroundColor="brandPrimary">
      <DrawerHeader fontFamily="graphik-medium" mb={6}>
        <Link> Bienvenue </Link>
      </DrawerHeader>
      <DrawerBody fontFamily="open-sans-semi-bold" fontSize="xs" color="white">
        <p>
          <Link onClick={e => handleLinkClick(e, '#apropos')}>A PROPOS</Link>
        </p>
        <br />
        <p>
          <Link onClick={e => handleLinkClick(e, '#methodologie')}>
            METHODOLOGIE
          </Link>
        </p>
        <br />
        <p>
          <Link onClick={e => handleLinkClick(e, '#references')}>
            PARCOURS ET REFERENCES
          </Link>
        </p>
        <br />

        <p>
          <Link onClick={e => handleLinkClick(e, '#contact')}>
            ME CONTACTER
          </Link>
        </p>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
</Flex>; */
}
