import React from 'react'
import '../../fonts/fonts.css';


import {
  Flex
} from '@chakra-ui/core';

const ArrowRight = () => {
 
  return (
    <Flex 
          size = "50%"  
          py = {6}
          justifyContent = "center"
       > 
          <svg width="100" height="129" viewBox="0 0 132 129" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M47.2441 89.1713L72.4341 64.5L47.2441 39.8287L54.9991 32.25L87.9991 64.5L54.9991 96.75L47.2441 89.1713Z" fill="#1F1F1F"/>
</g>
<defs>
<clipPath id="clip0">
<rect y="129" width="129" height="132" transform="rotate(-90 0 129)" fill="white"/>
</clipPath>
</defs>
</svg>
        </Flex>
  )
}

export default ArrowRight